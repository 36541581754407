import React, { ReactNode, useState } from "react";

interface RegisterUSerProviderProps {
  children: ReactNode;
}

interface RegisterUserContextType {
  registerUserTypeArr: string[];
  handleRegisterUserTypeCheckBox: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export const RegisterUserContext = React.createContext<RegisterUserContextType>({
  registerUserTypeArr: [],
  handleRegisterUserTypeCheckBox: () => {},
});

export const RegisterUserProvider = ({ children }: RegisterUSerProviderProps) => {
  const [registerUserTypeArr, setRegisterUserTypeArr] = useState<string[]>(["학생", "학부모"]);

  const handleRegisterUserTypeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const type = e.target.id;
    console.log("type", type);
    if (type === "전체") {
      if (checked) {
        setRegisterUserTypeArr(["전체", "학생", "학부모", "선생님", "테스터"]);
      } else {
        setRegisterUserTypeArr([]);
      }
    } else {
      if (checked) {
        setRegisterUserTypeArr(prev => [...prev, type]);
      } else {
        setRegisterUserTypeArr(prev => prev.filter(item => item !== type));
      }
    }
  };

  return (
    <RegisterUserContext.Provider
      value={{
        registerUserTypeArr,
        handleRegisterUserTypeCheckBox,
      }}
    >
      {children}
    </RegisterUserContext.Provider>
  );
};
