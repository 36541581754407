const isUrl =
  window.location.hostname === "localhost"
    ? "https://testapi.fineteacher.com/"
    : window.location.origin.replace("https://", "").split(".")[0] === "testadmin"
    ? "https://testapi.fineteacher.com/"
    : "https://api.fineteacher.com/";

// const isUrl = "http://localhost:4000/";

export default isUrl;
